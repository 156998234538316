nav ul.pagination {
  display: block;
  text-align: left;
}

nav ul.pagination li {
  display: inline-block;
}

nav ul.pagination li a {
  cursor: pointer;
  border: 0;
  background: #3D3D3D;
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
}

nav ul.pagination li a:hover {
  background: #3D3D3D;
}

nav ul.pagination li.active a,
nav ul.pagination li.active a:hover {
  background: #252525;
}
