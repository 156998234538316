$font-family-sans-serif: "Roboto-Light", "Helvetica Neue", Arial, sans-serif;

$font-size-base: 0.8rem;
//$font-size-lg: 16px;
//$font-size-sm: 10px;

// colors
$body-bg: #373737;
$body-color: #bfbebe;

// other
$border-radius: 0;

$eldro-green: #64a059;
$eldro-gold: #be995f;

$status-colors: (
  "green": $eldro-green,
  "orange": $eldro-gold,
  "red": #be0418,
  "black": #000000,
  "violet": #be29bc,
  "gray": #96969e,
);

$custom-standard-colors: (
  "primary": rgba(90, 80, 71, .7),
  "secondary": rgba(134, 142, 150, .7),
  "success": rgba(100, 160, 89, .6),
  "danger": rgba(173, 95, 52, .7),
  "info": rgba(110, 161, 255, .7),
  "warning": rgba(192, 144, 72, .7),
);

$theme-colors: map-merge((
    "eldro-white": #bfbebe,
    "eldro-light-gray": #9d9d9d,
    "eldro-light-gray-transparent": rgba(123, 123, 123, 0.2),
    "eldro-gold": #be995f,
    "eldro-green": #64a059,
    "eldro-orange": #ad5f34,
    "eldro-blue": #6ea1ff,
    "eldro-brown": #4e443b,
    "eldro-inset-dark": #242424,
    "eldro-inset-light": #515151,
    "eldro-inset-light2": #464646,
), $custom-standard-colors);

$table-hover-bg: rgba(#7b7b7b, 0.1);
